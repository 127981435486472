import { useState, useEffect } from 'react';
import { PermissionState, PermissionType } from '../models/types';
import Permissions from '../models/Permissions';
import BlockPermission from '../models/BlockPermissions';
import { isSafari, isFirefox } from '../../../../../../../utils/userAgent';

export default function usePermissions(requiredPermissions: BlockPermission, screenStreamRef: React.MutableRefObject<MediaStream | null>) {
  const [isInitialPermissionsLoaded, setIsInitialPermissionsLoaded] = useState(false);
  const [permissions, setPermissions] = useState<Permissions>(new Permissions());

  const [blockPermissionSettings, setBlockPermissionSettings] = useState<BlockPermission>(new BlockPermission(
    requiredPermissions.camera && permissions.camera === PermissionState.GRANTED,
    requiredPermissions.microphone && permissions.microphone === PermissionState.GRANTED,
    requiredPermissions.screen && permissions.screen === PermissionState.GRANTED,
  ));

  useEffect(() => {
    async function checkInitialPermissions() {
      try {

        let cameraStatus = { state: PermissionState.PROMPT };
        let microphoneStatus = { state: PermissionState.PROMPT };
        let screenStatus = { state: PermissionState.PROMPT };


        if (isSafari() || isFirefox()) {
          setPermissions(prev => {
            const updated = prev.clone();
            updated.camera = cameraStatus.state;
            updated.microphone = microphoneStatus.state;
            updated.screen = screenStatus.state;
            return updated;
          });
        } else {
          cameraStatus = await navigator.permissions.query({ name: PermissionType.CAMERA as unknown as PermissionName }) as { state: PermissionState };
          microphoneStatus = await navigator.permissions.query({ name: PermissionType.AUDIO as unknown as PermissionName }) as { state: PermissionState };
          screenStatus = await navigator.permissions.query({ name: 'display-capture' as unknown as PermissionName }) as { state: PermissionState };

          setPermissions(prev => {
            const updated = prev.clone();
            updated.camera = cameraStatus.state as PermissionState;
            updated.microphone = microphoneStatus.state as PermissionState;
            updated.screen = screenStatus.state as PermissionState;
            return updated;
          });
        }

        setBlockPermissionSettings(prev => {
          const updated = prev.clone();
          updated.camera = requiredPermissions.camera && cameraStatus.state === PermissionState.GRANTED;
          updated.microphone = requiredPermissions.microphone && microphoneStatus.state === PermissionState.GRANTED;
          updated.screen = requiredPermissions.screen && screenStatus.state === PermissionState.GRANTED;
          return updated;
        });

        setIsInitialPermissionsLoaded(true);
      } catch (error) {
        console.error('Error checking initial permissions: ', error);
      }
    }

    checkInitialPermissions();
  }, []);

  async function requestPermission(permissionId: PermissionType | [PermissionType.AUDIO, PermissionType.CAMERA]) {
    if (Array.isArray(permissionId)) {
      await requestCameraAndMicrophonePermission();
    }

    if (permissionId === PermissionType.SCREEN) {
      await requestScreenPermission(screenStreamRef);
    }

    if (permissionId === PermissionType.CAMERA) {
      await requestCameraPermission();
    }

    if (permissionId === PermissionType.AUDIO) {
      await requestMicrophonePermission();
    }
  }

  function updateBlockPermissionSettings(permissionId: PermissionType | [PermissionType.AUDIO, PermissionType.CAMERA], permissionState: PermissionState) {
    const value = permissionState === PermissionState.GRANTED;

    setBlockPermissionSettings(prev => {
      const updated = prev.clone();
      if (Array.isArray(permissionId)) {
        updated.camera = value;
        updated.microphone = value;
      } else {
        updated[permissionId] = value;
      }
      return updated;
    });

    setPermissions(prev => {
      const updated = prev.clone();
      if (Array.isArray(permissionId)) {
        updated.camera = permissionState;
        updated.microphone = permissionState;
      } else {
        updated[permissionId] = permissionState;
      }
      return updated;
    });
  }


  async function requestCameraPermission(): Promise<void> {
    try {
      if (permissions.camera === PermissionState.PROMPT) {
        await navigator.mediaDevices.getUserMedia({ video: true });
        updateBlockPermissionSettings(PermissionType.CAMERA, PermissionState.GRANTED);
      }
    } catch (error) {
      console.error('Error accessing camera: ', error);
      updateBlockPermissionSettings(PermissionType.CAMERA, PermissionState.DENIED);
    }
  };

  async function requestMicrophonePermission(): Promise<void> {
    try {
      if (permissions.microphone === PermissionState.PROMPT) {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        updateBlockPermissionSettings(PermissionType.AUDIO, PermissionState.GRANTED);
      }
    } catch (error) {
      console.error('Error accessing microphone: ', error);
      updateBlockPermissionSettings(PermissionType.AUDIO, PermissionState.DENIED);
    }
  };

  async function requestScreenPermission(screenStreamRef: React.MutableRefObject<MediaStream | null>): Promise<void> {
    try {
      const constraints = navigator.mediaDevices.getSupportedConstraints?.();
      const supportsDisplaySurface = constraints && 'displaySurface' in constraints;

      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: supportsDisplaySurface 
          ? { displaySurface: "monitor" } as unknown as MediaTrackConstraints
          : true
      });

      screenStreamRef.current = screenStream;
      updateBlockPermissionSettings(PermissionType.SCREEN, PermissionState.GRANTED);
    } catch (error) {
      console.error('Error accessing screen: ', error);
      updateBlockPermissionSettings(PermissionType.SCREEN, PermissionState.DENIED);
    }
  };

  async function requestCameraAndMicrophonePermission(): Promise<void> {
    try {
      if (permissions.camera === PermissionState.PROMPT && permissions.microphone === PermissionState.PROMPT) {
        await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        updateBlockPermissionSettings([PermissionType.AUDIO, PermissionType.CAMERA], PermissionState.GRANTED);
      }
    } catch (error) {
      console.error('Error accessing camera and microphone: ', error);
      updateBlockPermissionSettings([PermissionType.AUDIO, PermissionType.CAMERA], PermissionState.DENIED);
    }
  }

  return {
    permissions,
    blockPermissionSettings,
    setBlockPermissionSettings,
    isInitialPermissionsLoaded,
    requestPermission,
  };
};